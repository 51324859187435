
import React from 'react'


// styles
const copyrightStyle = {
    color: "#232129",
    fontSize:10,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft:10,
    paddingRight:10
  }

  const Copyright = () => (
        <div style={copyrightStyle}>
            <p>Site garanti <b>SANS cookies</b></p>
          <p>
            {new Date().getFullYear()} &copy; Jean-Christophe Ferelloc
          </p>
        </div>
    )

export default Copyright