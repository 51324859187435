import React from 'react'


export default function Temoignages () {
  const temoignages = [
    { date:"septembre 2022", autor : "Noémie", text: "Grâce à Laurence j'ai apprivoisé ma plus grande peur.\nJ'ai 15 ans et depuis que je suis toute petite j'ai la phobie des chiens. \
    Ma vie et celle de ma famille dépendaient de ma peur. Si il y avait un chien à 500 m, c'était juste impossible. \n\
    A chaque fois qu'on me proposait d'aller quelque part, la première chose qui me venait c'était  'et il y aura des chiens ?'. \n\
    Si je jugeais qu'il y avait trop de risques de croiser un chien, je trouvais une excuse pour ne pas y aller. Cette phobie était vraiment très handicapante. Je ne pouvais pas vivre 'normalement'.\n\
    Un jour j'ai décidé que je devais dompter cette peur, mais de moi-même je n'y arriverais pas. J'ai alors essayer plusieurs choses mais rien n'y faisait. J'ai décidé de demander de l'aide à Laurence. Avec 3-4 séances j'ai réussi à dompter ma phobie ! Laurence a su me mettre suffisamment à l'aise pour lui parler et pour être réceptive.\n\
    Laurence est un guide qui, à travers des propositions et des suggestions, nous aide à trouver les ressources pour devenir maître de situations, ne plus me laisser envahir."},
    { date:"septembre 2022", autor : "Christelle", text: "1ère séance avec Laurence,  c'est une personne très bienveillante,  extrêmement respectueuse, avec une écoute très active. Hyper sensible à notre langage corporel elle fait tout pour que l'on se sente bien,  en sécurité,  en pleine sérénité... Hâte de poursuivre avec elle mon chemin vers le bien être intérieur..Je recommande vivement."},
    { date:"décembre 2022", autor : "Monique", text: "Merci Laurence pour l'accompagnement dans des moments compliques de la vie.\n\
    Je me posais beaucoup de question sur l'hypnose. J'ai été très surprise du résultat. J'en suis sortie apaisée. Ca m'a fait un bien terrible. J'ai eu l'impression de faire un '' break ''dans ma vie et de repartir  sur d'autres bases.\n\
    Il ne faut pas hésiter. On ne se fait pas assez aider dans les moments durs. On pense que rien ne peut nous apaiser, mais si, il existe des solutions."},
    { date:"mars 2023", autor : "Solen", text: "Je suis ravie de ma séance avec Laurence, qui m'a mise très à l'aise dans son cabinet très agréable. J'avais besoin de me poser et d'apaiser des craintes. Laurence a une voix posée et agréable qui nous transporte vers un bien-être. Elle nous guide mais nous laisse aussi trouver les ressources et les sens qui nous correspondent car au fond de nous, nous savons ce qui est juste. J'ai ainsi découvert qu'une odeur en particulier m'apaise, je l'ai associé à la couleur mauve et la confiance m'a totalement enveloppée. Laurence nous invite à être autonome avec cet outil et à le réutiliser quand on en a besoin, ce que j'ai déjà fait ! Merci beaucoup Laurence ! Je recommande à 100% !"},
    { date:"septembre 2023", autor : "Nathalie", text: "Une très belle rencontre, un accompagnement de qualité, des émotions qui se sont apaisées et une place que je m'accorde désormais en conscience sans culpabilité. \nMerci Laurence pour se bout de chemin fait ensemble." },
    { date:"septembre 2023", autor : "Gaëlle", text: "Laurence est à l'écoute, et nous met à l'aise très rapidement. Sa douceur et sa sérénité nous enveloppe et nous met en confiance. Merci de cette belle expérience qui m'accompagne chaque jour."},
    { date:"novembre 2023", autor : "Klervie", text: "Mon petit garçon de 8 ans était ravi de sa première séance avec Laurence, elle lui a permis d'exprimer ses émotions et l'a aidé à les accepter, nous allons continuer les rendez-vous." },
    { date:"décembre 2023", autor : "Sabrina", text: "Ma fille faisait des crises d’angoisses. La technique que lui a montré Laurence fonctionne. \nRien à filtré de cette séance pour moi mais elle ne fait plus de crise d'angoisse et c'est le principal. \nJe vous remercie beaucoup de votre aide." },
    { date:"janvier 2024", autor : "Marie-Claude", text: "Très professionnelle, à l'écoute.\nJe recommande" },
    { date:"", autor : "", text: ""},
  ]
  console.log(temoignages)
  return (
      <main>

        {
        
        temoignages.map((temoignage) => {
          if (temoignage.autor && temoignage.text) {
            return (<div key={temoignage.autor}>  
              <h2  style={{display:"inline-block"}}>{temoignage.autor}</h2><span style={{display:"inline-block"}}>&nbsp;({temoignage.date})</span>
              <p>
              <b>&laquo;&nbsp;</b><span style={{whiteSpace: "pre-line"}}>{temoignage.text}</span><b>&nbsp;&raquo;</b>
              </p>
            </div>)
          }
        })}
        
      </main>
    )
}