import React from 'react'
import Background from "../images/background.jpg"
import Praticienne from "../components/subPages/praticienne"
import Hypnose from "../components/subPages/hypnose"
import Temoignages from "../components/subPages/temoignages"
import Indications from "../components/subPages/indications"
import Prestations from "../components/subPages/prestations"
import Contact from "../components/subPages/contact"

// styles
const contentStyle = {
    color: "#232126",
    backgroundImage: "url(" + Background + ")",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: 20,
    marginTop:2,
    minHeight:200
}

function subPage(id) {
    switch (id){
        case "praticienne":
            return Praticienne();
        case "hypnose":
            return Hypnose();
        case "indications":
            return Indications();
        case "prestations":
            return Prestations();
        case "temoignages":
            return Temoignages();
        case "contact":
            return Contact();
        default:
            return Praticienne();
            
    }
}

const Content = ({ title, id }) => (
    <div id={id} className="content" style={contentStyle}>
        <h1>{ title }</h1>
        {subPage(id)}
    </div>
    
)


export default Content