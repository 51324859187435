import React from 'react'

import logo from '../images/logoA&H.jpg'
import mail from '../images/email.svg'
import phone from '../images/phone.svg'
import facebook from '../images/logo-facebook.svg'
import Navbar from './navbar' 
import "../assets/css/styles.css"


const contactIconsStyle = {
  height:"15px",
  marginLeft:0,
  marginRight:10
}

const Sidebar = () => (
    <div className="sidebar" >
        <a href="/"><img className="imgLogo" style={{maxWidth:"60%",width: "auto", height: "auto"}}
            alt="Carte de visite"
            src={logo}
        /></a>
        <Navbar />
        <div className="footer">
        <a href="tel:0606423252"><img src={phone} alt="telephone" style={contactIconsStyle}/>06 06 42 32 52</a >
        <a href="mailto:laurence.bodennec@free.fr"><img src={mail} alt="email" style={contactIconsStyle}/>laurence.bodennec@free.fr</a >
        <a href="https://www.facebook.com/Laurence-Bodennec-accompagnement-et-hypnose-111551404985801/">
          <img src={facebook} alt="facebook" style={contactIconsStyle}/>laurence.bodennec</a >
        </div>
    </div>
)

export default Sidebar