import React, { useEffect } from 'react'

import logo from '../images/circlegoldtext5.jpg'
import menu from '../images/scrollTop.webp'
import "../assets/css/styles.css"

const logoStyle = {
    paddingTop:20, 
    height:200, 
    backgroundPosition: 'center',

    width:"100%",
    fontWeight:"bold",
    display:"flex", 
    flexDirection: "column", 
    alignItems:"center",
    justifyContent:"center",
    backgroundImage: "url(" + logo + ")",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
}

export default function  ImgLogoVertical () {
    
    const heightMin = 50
    const heightMax = 200
    const scrollMax = 150

    function setProps(pHeight) {
        let d = document.getElementById("logoArea").style
        d.setProperty("height",pHeight.toString()+"px")
        let op = pHeight/(-scrollMax+100)+1.3
        document.getElementById("iconMenu").style.setProperty("opacity",op.toString())

    }
    
    useEffect(() => {
        let lastKnownScroll
        document.addEventListener('scroll', _ => { 
            if (window.scrollY > scrollMax) {
                if ( lastKnownScroll !== scrollMax){
                    lastKnownScroll = scrollMax
                    setProps(heightMax-scrollMax)
                }
            } else {
                lastKnownScroll = window.scrollY
                let h = heightMax-window.scrollY 
                h=h<heightMin?heightMin:h
                setProps(h)
            }
        })
    })

    function resetScroll() {
        window.scrollTo(0,0 )
        setTimeout( () => document.location.href="/#", 1000 )
    }

    return (
        <div className="divLogoVertical" onClick={resetScroll}>
            <img alt="logo" id="iconMenu" src={menu} style={{height:40, position:"absolute", top:15, right:10, opacity:0}}/>
            <div id="logoArea" style={logoStyle}/>
        </div>
    )
}
