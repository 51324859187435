import React from 'react'
import Background from "../images/losange.jpg"

// styles
const navbarStyle = {
    color: "#232129",
    backgroundColor: "#FFFFFF",
    padding: 20,
    backgroundImage: "url(" + Background + ")",
    backgroundPosition: 'center',
    backgroundSize: 400,
    backgroundRepeat: 'no-repeat',
}

const Navbar = () => (
    <div className="navBar" style={navbarStyle}>
        <a href="#praticienne">Votre praticienne</a>
        <a href="#hypnose">L'hypnose</a>
        <a href="#indications">Indications</a>
        <a href="#prestations">Tarifs</a>
        <a href="#temoignages">Témoignages</a>
        <a href="#contact">Pratique</a>
    </div>
)

export default Navbar