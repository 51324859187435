import React from 'react'


export default function Indications () {
  return (
      <main>
        <h3><p>
        J'accompagne les enfants à partir de 7 ans, les adolescents et les adultes. L'accompagnement se fait en suivi individuel (avec ou sans hypnose) ou en groupe avec thématique(s).
        </p></h3>
            <ul>
                <li>Charge mentale</li>
                <li>Stress</li>
                <li>Burnout</li>
                <li>Fatigue</li>
                <li>Sommeil</li>
                <li>Deuil</li>
                <li>Phobies (animaux, transports, foule...)</li>
                <li>Troubles alimentaires</li>
                <li>Confiance, affirmation et estime de soi</li>
                <li>Mal-être psychique</li>
                <li>Apaiser les douleurs</li>
                <li>Dépendances (tabac, alcool...)</li>
            </ul>
        </main>
    )
}