
import React from 'react'


export default function Hypnose () {
  return (
      <main>
 
        <p>
        Besoin de mettre des mots sur vos maux ? De « vider votre sac » ?. Je vous accompagne. Vous
souhaitez aller plus loin ? Alors l’outil hypnose permet de changer, diminuer, améliorer, transformer,
faire autrement…<br/>
L’hypnose Ericksonienne, du nom de son fondateur Milton Erickson, permet d’activer vos
ressources.<br/>
L’hypnose est un état modifié de conscience , « être là sans être là », « être dans la lune », « être
dans sa bulle ». C’est un état naturel que tout être humain connaît quotidiennement quand vous êtes
« absorbés » par quelque chose, par exemple lorsque vous lisez un livre, regardez un film, ou
conduisez.<br/><br/>
Lors d’une séance, vous êtes entre le sommeil et la veille. Cet état permet de trouver vos propres
solutions, d’avoir donc des outils pour mieux gérer votre quotidien et vous sentir mieux.
<br/>
</p>
<h3><p>Je conçois l’hypnose comme un outil. Le praticien en hypnose ne pose pas de diagnostic, ne vous
parle pas de guérison :&nbsp;
<u>il vous accompagne vers un mieux-être</u>.</p></h3>

</main> 
  )
}