import React from 'react'
import mail from '../../images/email.svg'
import phone from '../../images/phone.svg'
import facebook from '../../images/logo-facebook.svg'
import localisation from '../../images/localisation.svg'


const contactIconsStyle = {
    height:"15px",
    marginLeft:0,
    marginRight:10
}

export default function Contact () {
  return (
      <main>
 <h2>Je reçois sur rendez-vous à mon cabinet.</h2>
 <h3>Les séances en groupe se déroulent au cabinet ou dans tout autre lieu sur demande</h3>
        <p>
          <a href="tel:0606423252"><img src={phone} alt="telephone" style={contactIconsStyle}/>06 06 42 32 52</a ><br/>
          <a href="https://goo.gl/maps/o4iv8Z99GGw28BBe8"><img src={localisation} alt="localisation"  style={contactIconsStyle}/>16 rue de Kervaziou, 29860 Le Drennec</a ><br/>
          <a href="mailto:laurence.bodennec@free.fr"><img src={mail} alt="email" style={contactIconsStyle}/>laurence.bodennec@free.fr</a ><br/>
          <a href="https://www.facebook.com/Laurence-Bodennec-accompagnement-et-hypnose-111551404985801/"><img src={facebook} alt="facebook" style={contactIconsStyle}/>laurence.bodennec</a ><br/>
        </p>
        <p>Secteur Lesneven, Ploudaniel, Plabennec, Plouvien, Lanarvily, Le Folgoët ...</p>
         
        </main>
    )
}